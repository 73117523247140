<template>
  <div id="main-login">
    <v-container id="signinup-form" class="fill-height">
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-row class="">
            <v-col cols="12" class="pt-6 pb-6">
              <v-card-text>
                <v-container fluid class="fill-height justify-center mb-10">
                  <h1>Conciliar-Me</h1>
                </v-container>

                <v-form
                  id="loginForm"
                  ref="loginForm"
                  v-model="validLoginForm"
                  class="signup-form-form"
                  @submit.prevent="handleLogin"
                >
                  <v-text-field
                    id="login"
                    v-model="login"
                    label="Usuário"
                    name="login"
                    append-icon="mdi-account"
                    type="text"
                    color="primary"
                    :rules="[obrigatorio]"
                  />

                  <v-text-field
                    id="password"
                    v-model="senha"
                    label="Senha"
                    name="password"
                    append-icon="mdi-lock"
                    type="password"
                    autocomplete="off"
                    color="primary"
                    :rules="[obrigatorio]"
                  />

                  <div class="text-center mt-6">
                    <v-btn
                      :disabled="!validLoginForm"
                      :loading="loginLoading"
                      type="submit"
                      large
                      color="primary"
                    >
                      Entrar
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-container>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </div>
</template>

<script>
import { obrigatorio } from '@/shareds/regrasForm'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import Snackbar from '@/models/ui/snackbar'
import PermissaoService from '@/services/usuario/permissao'
import store from '@/store'
import axios from 'axios'

export default {
  components: {
    SnackbarMessage
  },

  data: () => ({
    login: '',
    senha: '',
    email: '',
    loginLoading: false,
    validLoginForm: true,
    snackbar: new Snackbar(),
    obrigatorio: obrigatorio
  }),

  methods: {
    async handleLogin() {
      this.$refs.loginForm.validate()

      if (!this.validLoginForm) return

      this.loginLoading = true

      try {
        const baseUrl = process.env.VUE_APP_BACKEND_API || process.env.baseURL
        const response = await axios.post(`${baseUrl}/login`, {
          login: this.login.toLowerCase(),
          senha: this.senha
        })

        const { data } = response
        store.commit('setToken', data.token)
        //store.commit('setUsuario', data.usuario)

        const permissoes = await PermissaoService.buscarRotinasPermitidasPeloUsuario()
        store.commit('setPermissoes', permissoes)

        this.$router.push('/')
      } catch (error) {
        if (error.response) {
          const { data } = error.response
          this.snackbar.snackTextParam = `${data.status} | ${
            data.message ? data.message : 'Não foi possível autenticar o usuário'
          }`
        } else {
          this.snackbar.snackTextParam = `503 | Não foi possível se comunicar com o servidor`
        }
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.loginLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
a.no-text-decoration {
  text-decoration: none;
}

#main-login {
  background-color: #8c0005;
  background-image: linear-gradient(515deg, #8c0005 30%, #d53b40 90%);
  background-size: cover;
  height: 100%;
  width: 100%;
}

.signup-form-form {
  max-width: 25rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
